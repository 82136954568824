.form-select-lg{
    width: 95px !important;
    font-size: 15px !important;
    height: 100%;
    margin-right: 10px;
}

.currencyDropdown_option{
    font-family: Besley !important;
}

.form-select-lg:hover{
    cursor: pointer;
}