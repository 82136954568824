.manageSubPage_content{
    width: 90%;
    margin: auto;
    padding-top: 2rem;
    height: 500px;
    max-width: 30rem;
    input{
        width: 100%;
        height: 4rem;
        font-size: 1.5rem;
        padding: 1rem;
    }
}

.manageSubPage{
    margin-top: 0;
}

.subscriptionDetailsContainer{
    margin-top: 2rem;
    margin: auto;
    width: 90%;
    font-size: 20px;
    span{
        font-weight: bold;
    }
}

.fetchSubscription,.cancelSubscription{
    width: 18rem;
    margin-left: auto;
    margin-right: auto;
}