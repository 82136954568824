@import "_mixins.scss";
// common styling start ******************************************************************

body {
	// background-color: $grey;
	overflow-x: hidden;
}

iframe.tw-video-embed {
    width: 100%;
    height: 551px;
}

.top-space{
	margin-top: 20px;
}

.border{
	&--red{
		border: 1px solid $red;
	}
}

.boldText {
	font-weight: bold;
}

.align--right{
	text-align: right;
}

.align--left{
	text-align: left;
}

.tw-container{
	max-width: 1366px;
	margin: 0 auto;
	overflow: hidden;
}

label{
	color: $text-color;
}

.posted-comment-alert{
	color: $red;
	font-family: 'Oswald', sans-serif;
	font-weight: 500;
	font-size: 1.2em;
	padding: 30px;
}

// textbox styling start ************************************************************

[type="checkbox"]:checked+label:before{
	border-right: 2px solid $red;
	border-bottom: 2px solid $red;
}

[type="checkbox"].filled-in:checked+label:after{
	border: 2px solid $red;
	background-color: $red;
}

[type="checkbox"].filled-in:not(:checked)+label:after{
	border: 2px solid $red;
}

[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:after{
	background-color: $red;
}

[type="radio"]:checked+label:after, [type="radio"].with-gap:checked+label:before, [type="radio"].with-gap:checked+label:after{
	border-color: $red;
}

input[type=text]:focus:not([readonly]){
	border-bottom: none;
	box-shadow: none;
}

input[type=password]:focus:not([readonly]){
	border-bottom: none;
	box-shadow: none;
}

input[type=email]:focus:not([readonly]){
	border-bottom: none;
	box-shadow: none;
}

input[type=number]:focus:not([readonly]){
	border-bottom: none;
	box-shadow: none;
}

input[type=number]{
	background-color:  $text-box-background;
	border-bottom-color: transparent;
}

.tw-textbox{
	input[type='text'],input[type='email'],input[type='password'],input[type='number']{
		background-color: $text-box-background;
		border-bottom: none;
		color: $text-color;
		padding-left: 10px;
	}

	::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
	    color:  $text-color;
		padding-left: 10px;
		text-transform: capitalize;
		padding-left: 10px;
	}
	::moz-placeholder { /* Mozilla Firefox 4 to 18 */
	   color:  $text-color;
	   padding-left: 10px;
	   text-transform: capitalize;
	   padding-left: 10px;
   }
   ::-moz-placeholder { /* Mozilla Firefox 19+ */
	   color:  $text-color;
	   padding-left: 10px;
	   text-transform: capitalize;
	   padding-left: 10px;
   }
   ::-ms-input-placeholder { /* Internet Explorer 10-11 */
	   color:  $text-color;
	   padding-left: 10px;
	   text-transform: capitalize;
	   padding-left: 10px;
   }
	::-ms-input-placeholder { /* Microsoft Edge */
	   color:  $text-color;
	   padding-left: 10px;
	   text-transform: capitalize;
	   padding-left: 10px;
   }

}

// textbox styling end ************************************************************


textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: transparent;
    box-shadow: none;
}

textarea.materialize-textarea {
	border-bottom: transparent;
}

button:focus{
	background-color: transparent;
}

.btn:focus, .btn-large:focus, .btn-floating:focus, .btn-large:hover {
    background-color: #b71c1c;
}

.disabled{
	opacity: 0.5;
}

.tw-checkbox{
	margin: 10px;
	display: inline-table;
}

input[type="text"]:disabled {
    opacity: 0.5;
}

input[type="submit"]:disabled {
    opacity: 0.5;
}

// ****************************************************************************************

.card {
	&__featured {
		width: 60%;
	}
}

.tag {
	background-color: $red;
	padding: 2px 5px;
	color: white;
	display: inline-block;
	text-transform: uppercase;
	margin-right: 10px;
	margin: 2px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 2px;
}

.opinion-story-label {
	background-color: #D4D441;
	padding: 2px 5px;
	color: white;
	display: inline-block;
	text-transform: uppercase;
	margin-right: 10px;
	margin: 2px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 2px;
}

.analysis-story-label {
	background-color: #1AA1DF;
	padding: 2px 5px;
	color: white;
	display: inline-block;
	text-transform: uppercase;
	margin-right: 10px;
	margin: 2px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 2px;
}
	
.reportage-story-label {
	background-color: #389EBFE8;
	padding: 2px 5px;
	color: white;
	display: inline-block;
	text-transform: uppercase;
	margin-right: 10px;
	margin: 2px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 2px;
}

.thumbnail {
	&__featured {
		width: 60%;
	}
}

.loading{
	background: url('./icons/loading.gif');
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	position: relative;
	top: 50px;
}

#initial-load.loading {
	position: relative;
	top: 0
}

.error-modal{
	text-align: center;
	font-size: 1em;
	p{
		color: $red;
	}
}

.profile__update--text{
	font-family: 'Oswald', sans-serif;
	font-weight: 500;
	color: $red;
	text-align: center;
}

.sidebar-tw-logo{
	background: url('./icons/wirelogo_white.png');
	background-repeat: no-repeat;
	background-size: 100%;
	padding: 30px;
	position: relative;
	top: 50px;
}

.message{
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
	color: $red;
	padding-left: 15px;
}

.default-img{
	background-image: url('../stylesheet/icons/default-img.png');
	background-repeat: no-repeat;
	background-size: 100%;
	padding: 30px;
}

#refreshContent:hover{
	cursor: pointer;
}

.refresh-cw-icon-red{
	width: 24px;
	height: 24px;
	float: right;
	background-size: 80%;
	background-repeat: no-repeat;
	background: url('../stylesheet/icons/refresh-cw.svg');
}
// common stylings end ******************************************************************


//  styling for Autocomplete start -----

div.filter-panel > div {
	width: calc(100% - 460px);
  margin: 0px 20px 0px 20px;

	input {
		border-bottom-color: $red;
		margin-bottom: 0;
		box-sizing: border-box;
	}

	input:focus:not([readonly]){
		border-bottom: 1px solid $red;
		box-shadow: 0 1px 0 0 $red;
	}

	input[type=search]::-webkit-input-placeholder { /* WebKit browsers */
		@include input-search;
	}
	input[type=search]:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		@include input-search;
	}
	input[type=search]::-moz-placeholder { /* Mozilla Firefox 19+ */
	   @include input-search;
	}
	input[type=search]:-ms-input-placeholder { /* Internet Explorer 10+ */
	   @include input-search;
	}

	div{
		z-index: 100;
	}
}


//  styling for Autocomplete end -----



// about us styling start -----------------------------------------------------

.about-us{

    &__header-title--text{
        .bordered-card__header--text{
            text-transform: capitalize;
        }
    }

	&__sidebar{
		&-title{
			text-transform: uppercase;
			font-size: 1.25em;
			padding: 60px 0px 20px 20px;
			font-family: 'Oswald', sans-serif;
			font-weight: 500;
		}

		&-content{
			padding: 20px 40px 0px 20px;
			font-family: 'PT Serif', serif;
			font-weight: 500;
			font-size:1.2em;
		}

		&-follow-title{
			text-transform: uppercase;
			font-size: 1.2em;
			padding: 45px 0px 10px 18px;
			font-family: 'Oswald', sans-serif;
			font-weight: 500;
			display: inline-block;
		}

		&-social-link{
			@include social-buttons;
			width: 0px;
		    display: inline-table;
		    padding: 0px;
		    position: relative;
		    left: 80px;

			a{
				display: inline-block;
			}
		}

		&-subscribe{
			margin-top:40px;
		}
	}

}

.video-section {
	display: block;
	background-color: $video-gallery-background;
	overflow: hidden;
	padding-top: 60px;
	padding-bottom: 35px;
	width: 100%;
	margin-bottom: 22px;
}

// about us styling end ------------------------------------------------------



// icons start ----------------------------------------------------------------

// .icon-bookmark {
// 	background: url('../images/assets-sprite.png') no-repeat -49px -200px;
// 	width: 63px;
// 	height: 81px;
// 	position: absolute;
// 	right: 10px;
// 	display: inline-block;
// 	bottom: 0px;
// 	padding-bottom: 20px;
// }

// icons end ----------------------------------------------------------------




// EXPERIMENTAL no scroller

.tw-noscroll{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	top: 70px;
}

@media only screen and (max-width: 600px) {
	.tw-noscroll{
	  top: 70px;
	}
}

// EXPERIMENTAL no scroller end


.about-border{
	&--red{
		border: 0.5px solid $red;
		margin: 0px 50px 0px 20px;
	}
}

.no-post-message{

	height:200px;

}

.loading_white{
	background: url('./icons/loading_white.gif');
	background-size: 100%;
	width: 35px;
	height: 35px;
	margin: 0 auto;
	position: relative;
	top: 0px;
}