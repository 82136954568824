@import url( 'https://fonts.googleapis.com/css?family=Oswald:300,400,500|PT+Sans:400,700|PT+Serif:400,700,700i');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$grey: #e4e4e4;
$light-grey: hsl(0, 4%, 59%);
$red: #b71c1c;
$blue: #1aa1df;
$card-header-text: #939393;
$text-color: #626262;
$text-color: #464646;
$video-gallery-background: #383838;
$text-box-background: #EBEBEB;
$selected-menu:#ffffff;

$success-green: #4bb543;
$failure-red: #ff0000;

$home-featured-highlights-card-height-l: 389px;
$home-featured-highlights-card-height-m: 355px;

$home-page-headline-line-height: 1.5em;

$category-content-first-row-height-l: 480px;
$category-content-first-row-height-m: 480px;

$opinion-content-first-row-height-l: 480px;
$opinion-content-first-row-height-m: 480px;

$story-page-headline-color: #282828;
$story-page-short-desc-color: #686868;
$story-page-body-color: #282828;


@media only screen and (max-width: 600px) {

	.arrows{
		display: none;
	}

	.mobile-content-wrapper{
		// position: absolute;
		// top: 100px;
	}

	.card{

		&-stacked{
			margin-bottom: 0px;
		}

		&__author-name{
			width: auto;
		}

		&__full-image--height{
			height: auto;
		}
	}

	#card-with-border .card__footer{
		margin: 0px;
	}

	.red-background-on-mobile{
		background-color: $red;
	}

	.with-footer .card__footer{
		position: relative;
		top: -20px;
		margin: 0px;
	}

	#card-with-border{
		.card.horizontal {
			display: inline-block;
			width: 100%;
		}

		
	}

	#featured-card {
		.card__header {
			display: none;
		}
	}

	#most-viewed {

		.card.horizontal .card-stacked .card-content {
			box-shadow: none;
		}

		.card {
			background-color: rgba(255, 255, 255, 0);
			box-shadow: none;

			&__header {
				background-color: rgba(255, 255, 255, 0);
				font-size: 1.5em;
				box-shadow: none;
			}

			&-image {
				display: none;
			}

			&__footer {
				background-color: rgba(255, 255, 255, 0);
				margin-top: 30px;
				font-size: 1.3em;
				box-shadow: none;
			}
		}

	}

	#video-gallery-background{
		background-color: transparent;
		padding: 0px;
		margin: 0px;

		.bordered-card__content{
			padding: 0px;
		}

		.video-content-container{
			margin-left: 0px;
		}

		.video-content-container .video-frame iframe{
			height: 200px;
		}
	}

	#video-gallery-background{
		display: none;
	}

	

	#team-card{

		.card-image{
			img{
				float: left;
			}
		}

		.card.horizontal .card-stacked{
			margin: 0px;
			height: 100%;
		}

		.card.horizontal .card-content{
			height: 0px;
		}

		.team-card__header--text{
			font-size: 0.9em;
		}
	}

	.bg-grey.container{
		.col{
			padding: 0px;
		}
	}

	.card-vertical-for-mobile{
		.card.horizontal{
			display: inline-table;
			width: 100%;
		}

		.card.row-height .card-image {
			width: auto;
		}
	}

	.card.horizontal {
		.card-image {
			width: 100%;
			max-width: none;
		}

		.card__row--image{
			width: 100%;
		}

	}

	// header styling start -----------------------------
	.header__button {
		display: none;
	}

	.header {

		.header__top-nav {
			.header__logo{
				text-align: center;

				
			}
		}

		

		&__top-nav{
			height: 60px;
		}

		&__bottom {

			height: 35px;

			&-nav-link {
				font-size: 0.8em;
			}

			// &-nav{
			// 	margin: 0px;
			// 	width: 75%;
			// }

			&-nav a{
				padding: 7px;
			}
		}

		&__arrow{

			&-left{
				// left: 0;
			    // bottom: 30px;
			}

			&-right{
				// right: 10px;
				// margin-left: 0px;
				// bottom: 30px;
			}
		}
	}

	// header styling end --------------------------------


	// sub header styling start
	.sub-header{

		&__title{
			font-size: 1.5em;
		}

		&__links{
			padding-top: 10px;

			a{
				margin-bottom: 5px;
				margin-top: 10px;
			}
		}

		&__links--active:before{
			top: 20px;
		}
	}

	// sub header styling end

	// last week styling start ---------------------------
	// #last-week {
	// 	.card.horizontal {
	// 		display: inline;
	// 	}
	// }
	// last week styling end -----------------------------
}

.redText{
    color: $red;
}

@media only screen and (min-width: 601px){

	#team-card{
		.card__header{
			padding: 10px;
		}
	}

	.bordered-card__content{
		.card.vertical .card-image, .card.horizontal .card-image{
			max-width: none;
		}
	}

	.col{
		padding: 0px;
	}

	// latest page styling start -----

	.top-space.latest-page__full-image--width{
		max-width: 100%;
		height: auto;
	}

	// latest page styling end ------
}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

	.row.col.l3{
		width: 50%;
	}

}

@media only screen and (max-width: 900px) {
	.mobile-content{
		position: absolute;
		top: 140px;
	}
}

// fonts --------------------------------------------------------------------
/*
font-family: 'Oswald', sans-serif;
font-family: 'PT Serif', serif;
font-family: 'PT Sans', sans-serif;
*/


// --------------------------------------------------------------------------
