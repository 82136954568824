@import '../../App.scss';

#payment-container{
    min-height: 80px !important;
}

.paymentFormContainer{
    background-color: #fff;   
    border: 1px solid $grey;
    font-family: Besley;
    min-width: 200px;
    width: 50%;
    margin: auto;
}

.paymentContainer{
    background-color: #e4e4e4;
    width: 100%;
    height: 100%;
}

.amount-btn{
    background-color: $red !important;
    color: white !important;
    width: 32% !important;
    text-align: center !important;
    margin-right: 5px !important;
}

.payment-amountForm_view{
    text-align: center !important;
}

.payment-detailsForm_view, .payment-main_view{
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
}

#amount-container{
    display:flex; 
    padding-left: 40px;
    padding-right: 40px;
    input{
        text-align: center;
        font-size: 32px;
    }
}

.invalid-input{
    margin-bottom: 5px !important;
    border: 1px solid red
}

.help-text{
    text-align: left;
    padding-bottom: 0.75rem;
    font-size: 10px;
    i{
        border-left: 2px solid salmon;
        padding-left: 0.5rem;
    }
}

.back-btn{
    cursor: pointer;
    width: 30px;
    height: 23px;
    float: right;
    margin-top: 1rem;
    margin-right: 0.5rem;
    color: white;
    background-size: 80%;
    background-repeat: no-repeat;
    background: url('../../stylesheet/icons/arrow-left.svg');
}

#support-label, #btn-container{
    padding-left: 40px;
    padding-right: 40px;
}

#payment-content #payment-body{
    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
    border: 1px solid $red;
    width: 100%;
}

#payment-logo{
    margin-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $red;
    text-align: center;
    a > img{
        height: 50px;
        width: 100px;
    }
}

.supportTheWire_info, .addContactDetails_info, .reviewAndPay_info{
    font-size: 15px;
    font-weight: 500;
    line-height: 1.67;
    span{
        font-weight: 600;
    }
}

.reviewAndPay_info{
    margin-bottom: 20px;
}

.reviewAndPay{
    text-align: center ;
}

.supportTheWire_freq{
    padding-top: 20px;
    padding-bottom: 5px;
    div{
        // padding-bottom: 10px;
        border-radius: 6px; 
    }
}

.supportTheWire_freqBtnGrp{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
}


.freq-btn{
    background-color: white;
    color: #1d1d1d;
    text-align: center;
    padding: 13px 0px 13px 0px;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    opacity: 0.9;
}

.supportTheWire_amtBtnGrp{
    @extend .supportTheWire_freqBtnGrp;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 10px;
}

.amt-btn{
    @extend .freq-btn;
    border: solid 1px #363648;
    margin-right: 10px;
    font-weight: bold;
    opacity: 0.9;
    border: none;
}

.amt-btn_active{
    @extend .amt-btn;
    background-color: $red;
    color: white;
    border: none;
}

.freq-btn:hover, .amt-btn:hover{
    cursor: pointer;
}

.freq-btn_active{
    @extend .freq-btn;
    background-color: $red;
    color: white;
}

.supportTheWire_freq_label, .supportTheWire_amt_label, #amt_label, #freq_label, #contact_details_label{
    font-size: 15px;
    font-weight: bold;
    color: #1d1d1d;
    line-height: 1.67;
    margin-bottom: 10px !important;
}

.supportTheWire_amt{
    @extend .supportTheWire_freq;
}

.supportTheWire_amtInput {
    width: 100%;
    // padding-right: 20px;
    margin-top: 10px;
    input, input:focus{
        width: 100%;
        box-sizing: border-box;
        outline: none;
        height: 50px;
        padding: 0px 0px 0px 10px;
        border-radius: 6px;
        font-size: 15px;
        border: none;
        box-shadow: none !important;
    }
}
.continueBtnContainer{
    
    width: 100%;
    margin-top: 20px;
    .arrow-right-icon-white{
        width: 30px;
        height: 23px;
        float: right;
        background-size: 80%;
        background-repeat: no-repeat;
        background: url('../../stylesheet/icons/arrow-right-white.svg');
    }
    justify-content: space-between;
}

.proceedBtnContainer{
    @extend .continueBtnContainer;
    text-align: center;
}

.continue-button, .continue-button:visited, .continue-button:active, .continue-button:focus,
.proceed-button, .proceed-button:visited, .proceed-button:active, .proceed-button:focus{
    height: 50px;
    width: 100%;
    padding: 14px 21px 14px 20px;
    border-radius: 25px;
    background-color: $red;
    border: none;
    box-shadow: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 20px;
    text-align: left;
}

.continue-button:disabled, .proceed-button:disabled{
    opacity: 0.6 !important;
}

.continue-button:hover, .proceed-button:hover{
    cursor: pointer;
}

.failure-button, .failure-button:visited, .failure-button:active, .failure-button:focus{
    @extend .continue-button;
    background-color: $failure-red;
}

.supportTheWire_detsForm_name{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    div{
        width: 100%;
    }
}

.supportTheWire_detsForm{
    @extend .supportTheWire_amtInput;
    margin-top: 10px;
    padding-right: 0px;
    input{
        box-sizing: border-box;
        width: 100%;
        margin: 0 0 8px 0;
    }
}

#indianCitizenChckBx{
    all:revert;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background:transparent;
    border:0;
    vertical-align: bottom;
    // outline: 0 !important;
    cursor: pointer;
}

.supportTheWire_checkbox{
    label{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
        margin-left: 10px;
        padding-bottom: 5px;
    }
    margin-bottom: 20px;
}

.addContactDetails{
    padding-top: 20px;
    input{
        border: none !important;
    }
}
