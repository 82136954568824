@mixin bottom-icons {
	background-repeat: no-repeat;
	background-position: right top;
	padding: 20px;
	position: absolute;
	right: 70px;
	top: -5px;
}

@mixin input-search {
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
	font-size: 1.5em;
	position: relative;
	left: 10px;
}

@mixin bookmark-full-image {
	background-image: url('./icons/icons_bookmark-2-white.svg');
	background-repeat: no-repeat;
	background-position: right top;
}

@mixin icon {
	&-bookmark{
		background-image: url('./icons/icons_bookmark-2-gray.svg');
		@include bottom-icons;
	}

	&-bookmark-full-image{
		@include bookmark-full-image;
		bottom: 0;
	    position: absolute;
	    right: 30px;
	    padding: 20px;
	}
}

@mixin active-style {
	content: "";
	position: absolute;
	left: 10px;
	bottom: 0;
	height: 1px;
	width: 70%;
	border-bottom: 3px solid $red;
	top: 25px;
}

@mixin card__footer {
	background-image: url('./icons/icons_more.svg');
	background-repeat: no-repeat;
	background-position: right top;
	background-color: white;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);

	a{
		margin-right: 50px;
	}
}


@mixin card__footer {
	background-image: url('./icons/icons_more.svg');
	background-repeat: no-repeat;
	background-position: right top;
	background-color: white;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);

	a{
		margin-right: 50px;
	}
}

@mixin bottom-icons {
	background-repeat: no-repeat;
	background-position: right top;
	padding: 20px;
	position: absolute;
	right: 35px;
	bottom: 5px;
}

@mixin bookmark-full-image {
	background-image: url('./icons/icons_bookmark-2-white.svg');
	background-repeat: no-repeat;
	background-position: right top;
}

@mixin bookmark-full-image--filled {
	background-image: url('./icons/icons_bookmark-1.svg');
	background-repeat: no-repeat;
	background-position: right top;
}

@mixin profile-menu {
	i {
		display: inline-block;
		width: 30px;
		height: 30px;
		background-size: 100%;
		vertical-align: middle;
		margin-right: 10px;
		transition: all 0.3s;	
	}
	
	&--profile{
		background: url('./icons/profile-icon.png') no-repeat;

	}
	ul > li:hover &--profile,ul > li.active &--profile, &--profile:hover, &--profile.selected{
		background: url('./icons/profile-icon-selected.png') no-repeat;
	}
	&--newsletter{
		background: url('./icons/newsletters-icon.png') no-repeat;
	}
	ul > li:hover &--newsletter, ul > li.active &--newsletter, &--newsletter:hover, &--newsletter.selected{
		background: url('./icons/newsletters-icon-selected.png') no-repeat;
	}
	&--subscription{
		width: 29px !important;
		background: url('./icons/subscriptions-icon.png') no-repeat;
	}
	ul > li:hover &--subscription, ul > li.active &--subscription, &--subscription:hover, &--subscription.selected{
		background: url('./icons/subscriptions-icon-selected.png') no-repeat;
	}
}

@mixin social-buttons {
	padding: 30px 0px;
	width: 150px;
	a {
	  width : 20px;
	  height : 20px;
	  display: inline-block;
	  margin-left: 12px;
	}

	&--fb{
		background: url('./icons/icon_fb.png') no-repeat;
		background-size: 100%;
	}

	&--tw{
		background: url('./icons/icon_tw.png') no-repeat;
		background-size: 100%;
	}

	&--instagram{
		background: url('./icons/icon_insta.png') no-repeat top;
		background-size: 100%;
	}

	&--youtube{
		background: url('./icons/icon_youtube.png') no-repeat top;
		background-size: 100%;
	}

	&--whatsapp{
		background: url('./icons/icon_whatsapp.png') no-repeat top;
		background-size: 100%;
	}

	&--email{
		background: url('./icons/icon_youtube.png') no-repeat top;
		background-size: 100%;
	}
}

@mixin active-style {
	content: "";
	position: absolute;
	left: 10px;
	bottom: 0;
	height: 1px;
	width: 70%;
	border-bottom: 3px solid $red;
	top: 25px;
}

@mixin icon-common {
	background-size: 100%;
	width: 40px;
	height: 40px;
	display: inline-block;
}

@mixin icon {

	&--down{
		background: url('./icons/icons_down.svg') no-repeat top;
		background-size: 100%;
	}

	&-profile--red{
		background: url('./icons/icons_user_red.svg');
		@include icon-common;
	}

	&-collection-unfilled--red{
		background: url('./icons/icons_bookmark-2.svg');
		@include icon-common;
	}

	&-like-filled--red{
		background: url('./icons/icons_heart-1.svg');
		@include icon-common;
	}

	&-like-unfilled--red{
		background: url('./icons/icons_heart-2.svg');
		@include icon-common;
	}

	&-comment--red{
		background: url('./icons/icons_comment-1.svg');
		@include icon-common;
	}

	&-settings--red{
		background: url('./icons/icons_settings.svg');
		@include icon-common;
	}

	&-logout--red{
		background: url('./icons/icons_logout.svg');
		@include icon-common;
	}

	&-edit--red{
		background: url('./icons/icons_edit.svg');
		@include icon-common;
	}

	&-arrow--white{
		background: url('./icons/icons_arrow-2.svg') no-repeat top;
		background-size: 100%;
	}

	&-arrow--red{
		background: url('./icons/icons_arrow-2.svg') no-repeat top;
		background-size: 100%;
		width: 50px;
		height: 50px;
		border-color: transparent;
	}

	&-close--red{
		background: url('./icons/icons_close.svg') no-repeat top;
		position: relative;
		
		padding: 32px;
	}

	&-sidebar-close--red{
		background: url('./icons/icons_side_menu_close_red.svg') no-repeat top;
		position: relative;
		padding: 32px;
		position: relative;
		top: 0;
		left: 0;
		
	}

	&-side-menu{
		background: url('./icons/icons_side_menu.svg') no-repeat top;
		background-size: 100%;
		padding: 40px;
		position: relative;
		top: 40px;
		right: 10px;
	}

	&-side-home{
		background: url('./icons/icons_side_home.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-home--open{
		background: url('./icons/icons_side_home_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-categories{
		background: url('./icons/icons_side_categories.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-categories--open{
		background: url('./icons/icons_side_categories_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-opinion{
		background: url('./icons/icons_side_opinion.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-opinion--open{
		background: url('./icons/icons_side_opinion_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-staff-picks{
		background: url('./icons/icons_side_staff.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-staff-picks--open{
		background: url('./icons/icons_side_staff_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-video{
		background: url('./icons/icons_side_video.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-video--open{
		background: url('./icons/icons_side_video_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-heywire{
		background: url('./icons/icons_side_heywire.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-side-heywire--open{
		background: url('./icons/icons_side_heywire_active.svg') no-repeat top;
		background-size: 100%;
		padding: 20px;
		float: left;
		position: relative;
		top: 12px;
	}

	&-side-close{
		background: url('./icons/icons_side_menu_close.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
		position: relative;
		top: 40px;
	}

	&-mobile-menu{
		background: url('./icons/icons_menu_mobile.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
		position: relative;
		top: 0;
		left: 0;
	}

	&-parent-menu-home{
		background: url('./icons/icons_side_home.svg') no-repeat top;
		background-size: 100%;
		padding: 14px;
		position: relative;
		top: 10px;
	}

	&-change-pic{
		background: url('./icons/change-pic.png') no-repeat top;
		background-size: 100%;
		position: absolute;
		top: 0;
		width: 130px;
		height: 130px;
		cursor: pointer;
	}

	&-bookmark{
		background-image: url('./icons/icons_bookmark-2.svg');
		@include icon-common;
	}

	&-bookmark-full-image{
		@include bookmark-full-image;
		bottom: 0;
		position: absolute;
		right: 35px;
		bottom: 5px;
		padding: 20px;
	}

	&-bookmark--filled{
		background-image: url('./icons/icons_bookmark-1.svg');
		@include bottom-icons;
	}

	&-bookmark-white--filled{
		background-image: url('./icons/icons_bookmark-filled-white.svg');
		@include bottom-icons;
	}

	&-bookmark-full-image--filled{
		@include bookmark-full-image--filled;
		bottom: 0;
		position: absolute;
		right: 30px;
		padding: 20px;
	}

	&-slider-left{
		background: url('./icons/left-arrow.png') no-repeat top;
		background-size: 100%;
		padding: 10px;
		position: relative;
		top: 10px;
	}

	&-slider-right{
		background: url('./icons/right-arrow.png') no-repeat top;
		background-size: 100%;
		padding: 10px;
		position: relative;
		top: 10px;
	}
	&-fullscreen {
		background: url('./icons/icons_fullscreen.svg') no-repeat top;
		@include icon-common;
	}
	&-fullscreen-exit {
		background: url('./icons/icons_fullscreen_exit.svg') no-repeat top;
		@include icon-common;
	}
	&-slideshow {
		background: url('./icons/icons_slideshow.svg') no-repeat top;
		@include icon-common;
	}
	&-slideshow-red {
		background: url('./icons/icons_slideshow_red.svg') no-repeat top;
		@include icon-common;
	}
	&-share-red {
		background: url('./icons/icons_share_red.svg') no-repeat top;
		@include icon-common;
	}

	&-arrow-up{
		background: url('./icons/arrow_up.png') no-repeat top;
		background-size: 100%;
		padding: 10px;
		position: relative;
		top: 20px;
		left: 30px;
	}

	&-arrow-down{
		background: url('./icons/arrow_down.png') no-repeat top;
		background-size: 100%;
		padding: 10px;
		position: relative;
		top: 5px;
		left: 30px;
	}

	&-arrow-line--red{
		background: url('./icons/icons_arrow-1.svg') no-repeat top;
		background-size: 100%;
		padding: 30px;
		position: absolute;
		top: -10px;
		right: -30px;
	}

	&-trending{
		background: url('./icons/icons_trending.svg') no-repeat top;
		background-size: 100%;
		padding: 15px;
		position: relative;
		top: 10px;
		left: 0;
	}

	&-suggested{
		background: url('./icons/icons_Suggested.svg') no-repeat top;
		background-size: 100%;
		padding: 15px;
		position: relative;
		top: 10px;
		left: 0;
	}

	&-video{
		background: url('./icons/icons_Videos.svg') no-repeat top;
		background-size: 100%;
		padding: 15px;
		position: relative;
		top: 10px;
		left: 0;
	}

	&-videos{
		background: url('./icons/icons_Videos.svg') no-repeat top;
		background-size: 100%;
		padding: 15px;
		position: relative;
		top: 10px;
		left: 0;
	}

	&-search--white{
		background: url('./icons/icons_search-1.svg') no-repeat top;
		background-size: 100%;
		padding: 40px;
		position: relative;
		top: 39px;
		left: 0;
	}

	&-search--red{
		background: url('./icons/icons_search-2.svg') no-repeat top;
		background-size: 100%;
		position: relative;
		
	    
		padding: 32px;
	}

	&-share--grey{
		background: url('./icons/icons_share_grey.svg') no-repeat top;
		background-repeat: no-repeat;
	    background-position: right top;
	    padding: 20px;
	}

	&-share--red{
		background: url('./icons/icons_share_red.svg') no-repeat top;
		background-repeat: no-repeat;
	    background-position: right top;
	    padding: 20px;
	    // position: absolute;
	    // right: 0;
	    // bottom: 0;
	}

	&-plus--red{
		background: url('./icons/icons_plus.svg') no-repeat top;
		background-repeat: no-repeat;
		background-position: right top;
		padding: 20px;
	}

	&-copyright{
		background: url('./icons/icons_side_copyright.svg') no-repeat top;
		background-size: 100%;
		padding: 32px;
	}

	&-copyright--red{
		background: url('./icons/icons_side_copyright_red.svg') no-repeat top;
		background-size: 100%;
		
	}

}

@mixin video{
	&--red{
		background: url('./icons/icons_vid.svg') no-repeat top;
		background-size: 100%;
		width: 30px;
		height: 30px;
	}
}

@mixin card-image-as-background {
	background-color: #e4e4e4;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin highlighted-text-on-full-image-bkg {
	background-color: white;
	color: black;
	padding-left: 5px;
	padding-right: 5px;
	font-family: 'Oswald', sans-serif;
	font-weight: 500;
	line-height: 1.8em;
}

@mixin highlighted-text-on-full-image-bkg-grit {
	
	color: white;
	font-size:16px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
	margin-bottom:12px;
}