.headerMain{
    height: 60px;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    padding-left: 2rem;
    background-color: white;
}

.headerLink{
    font-size: 15px;
    padding-top: 18px;
    margin-right: 2rem;
    font-family: Besley;
    font-weight: 500;
    a{
        text-decoration: none;
        color: #1d1d1d;
    }
}

.header_socialGroup{
    // width: 120px;
    padding-top: 32px;
    justify-content: space-between;
    a{
        width: 23px;
        height: 23px;
        display: inline-block;
        margin-left: 12px;
    }
}

.headerMain_dropDown{
    margin-top: 0.8rem;
    display: none;
}

.headerMain_LoggedInContent{
    // float: right;
    margin-left: auto;
    span{
        @extend .headerLink;
        display: block !important;
    }
}

.headerMain_LoggedInContent a:hover{
    cursor: pointer;
}

@media only screen and (max-width: 1060px) {
    .headerLink{
        display: none;
    }
    .headerMain_dropDown{
        display: block;
    }
}