body {
	// background-color: $grey;
}

a{

	color:#b71c1c;

}

.top-space{
	margin-top: 20px;
}

.card {
	background-color:#ffffff;
	&__featured {
		width: 60%;
	}
}

.tag {
	background-color: $red;
	padding: 5px;
	color: white;
	display: inline-block;
	text-transform: uppercase;
	margin-top: 5px;
	margin-right: 5px;
	font-family: 'Oswald', sans-serif;
	font-weight: 400;
    font-size: 12px;
}

.thumbnail {
	&__featured {
		width: 60%;
	}
}

#for-scroll{
	position: fixed;
	top: auto;
	bottom: -15px;
	left: 0;
	right: 0;
}

// icons start ----------------------------------------------------------------

.icon {
	&-bookmark{
		background-image: url('./icons/icons_bookmark-2-gray.svg');
		@include bottom-icons;
	}

	&-bookmark-full-image{
		@include bookmark-full-image;
		bottom: 0;
	    position: absolute;
	    right: 30px;
	    padding: 20px;
	}
}

// icons end ------------------------------------------------------------------
