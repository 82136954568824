@import '../../App.scss';

#payment-container{
    min-height: 80px !important;
}

.paymentContainer{
    background-color: #e4e4e4;
    width: 100%;
    height: 100%;
}

.paymentContainer_thankyou_icon{
    padding-top: 30px;
    text-align: center;
}

.paymentContainer_thankyou_text, .paymentContainer_thankyou_socialIcons{
    padding-top: 20px;
    width: 85% !important;
    line-height: 25px;
    button{
        margin-right: 10px;
    }
}
