@import '../../App.scss';

.supportPage{
    font-family: Besley;
    a{
        text-decoration: none;
        color: $red;
    }
}

.supportPage_welcomeBanner{
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
    padding-top: 5rem;
    width: 90%;
}

.supportPage_content_info_video_iframe{
    width: 100%;
    height: 16rem;
}

.supportPage_welcomeBanner_logo{
    img{
        height: 65px;
        width: 185px;
    }
    margin-bottom: 2rem;
}

.supportPage_welcomeBanner_text{
    font-size: 30px;
    font-weight: 600;
    color: $red;
    font-family: Besley;
}

.supportPage_content{
    margin:auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    div{
        margin-bottom: 1rem;
    }
}

.supportPage_content_info{
    width: 48%;
}

.supportPage_content_payment{
    width: 48%;
    div{
        margin-bottom: 0px !important;
    }
}

.supportPage_content_payment > div > div{
    width: 90%;
    margin: auto;
}

.supportPage_content_info_header{
    display: flex;
    // justify-content: space-between;
}

.supportPage_content_info_header_text{
    font-family: Besley;
    font-size: 20px;
    font-weight: 600;
    margin-right: 1rem;
    width: 15rem;
    white-space: nowrap;
}

.supportPage_content_info_header_line {
    width: 100%;
    height: 2px;
    margin-top: 0.7rem;
    display: inline-block;
    background-color: #979797;
}

.supportPage_content_payment_header{
    background-color: #e4e4e4;
    width: 100%;
    box-sizing: border-box;
    height: 4rem;
    padding-top: 0.8rem;
    // padding-bottom: .5rem;
    padding-left: 1.7rem;
    margin-bottom: 0 !important;
    border-bottom: solid 1px #979797;
    font-family: Besley;
    font-size: 25px;
    font-weight: 600;
}

.supportPage_content_payment_iframe{
    @extend .supportPage_content_payment;
    margin: auto;
    width: 95%;
}

@media only screen and (max-width: 1000px) {
    .supportPage_content {
     width: 95%;
    }
}

@media only screen and (max-width: 850px) {
    .supportPage_content {
     width: 95%;
     flex-direction: column;
    }
    .supportPage_content > div{
        width: 100%;
        margin: auto;
    }

    .supportPage_content_payment_header{
        font-size: 20px;
    }

    .supportPage_welcomeBanner_logo{
        img{
            height: 50px;
            width: 135px;
        }
    }
}